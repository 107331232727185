@if (contract?.title; as title) {
  <h1 mat-dialog-title>{{title}}</h1>
}

<mat-dialog-content>
  <p>{{description()}}</p>

  @if (contract?.additionalInformation; as addInfo) {
    <p>{{addInfo}}</p>
  }

  @if (contract?.metadata; as metadata) {
    <div class="metadata">
      @for (item of metadata | keyvalue; track item.key) {
        <dt>
          <strong>{{item.key}}</strong>
        </dt>
        <dd>{{item.value}}</dd>
      }
    </div>
  }
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>

  <button mat-flat-button color="primary" [mat-dialog-close]="true">
    {{buttonText()}}
  </button>
</mat-dialog-actions>
